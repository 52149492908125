import Shell from "@shared/Shell";
import TransactionEntry from "@components/TransactionEntry2";

export function Till() {

  return (
    <Shell pageIndex={0}>
      <div>
        <TransactionEntry />
      </div>
    </Shell>    
  );
}

export default Till;
