
import Table from "@shared/Table";
import { formatPrice } from "@shared/Utils";

/*
  stallholder: string;
  description: string;
  stockid: string;
  price: number;
*/
const columns = [
  {
    "id": "stallholder",
    "label": "Dealer",
    "cellStyle": (row: number) => "px-2 py-1 font-medium text-lg text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800",
    "headerStyle": "px-2 py-3 bg-gray-50 dark:bg-gray-800"
  },
  {
    "id": "description",
    "label": "Description",
    "cellStyle": (row: number) => "px-2 py-1 text-lg",
    "headerStyle": "px-2 py-4"
  },
  {
    "id": "stockid",
    "label": "Stock ID",
    "cellStyle": (row: number) => "px-2 py-1 text-lg",
    "headerStyle": "px-2 py-4"
  },
  {
    "id": "price",
    "label": "Price",
    "cellStyle": (row: number) => "px-2 py-1 text-lg",
    "headerStyle": "px-2 py-4",
    "format": (cell: any, row: {}) => { 
      return formatPrice(cell);
    }
  }
]

type TableProps = {
  data: {}[];
};

export const TransactionTable = ({ data }: TableProps) => {

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

        <Table data={data} columns={columns}/>
      </div>
    </>
  );
}

export default TransactionTable;
