import { ReactNode, createContext } from "react";
import { useState, useEffect } from "react";
import { Stallholder} from '@sharedtypes/Stallholder';
import { Cache } from 'aws-amplify/utils';

const STALLHOLDER = "selected_stallholder";


export type StallholderContextValue = {
  stallholder: Stallholder | undefined;
  setStallholder: (stallholder: Stallholder | undefined) => void,
};

const StallholderContext = createContext<StallholderContextValue | undefined>(undefined);

type StallholderContextContextProps = {
  children?: ReactNode
};


export const StallholderProvider = ({ children }: StallholderContextContextProps) => {

  const [stallholder, setStallholder] = useState<Stallholder | undefined>(undefined);

  useEffect(() => {

    Cache.getItem(STALLHOLDER)
    .then(result => {
      if(result) {
        setStallholder(result);
      }
    })
    return () => {
    };
  }, []);   

  useEffect(() => {

    if (stallholder === undefined) 
      Cache.removeItem(STALLHOLDER);
    else
      Cache.setItem(STALLHOLDER, stallholder);
    
    return () => {
    };
  }, [stallholder]);   

  return (
    <StallholderContext.Provider value={{stallholder, setStallholder}}>
      {children}
    </StallholderContext.Provider>
  );
};


export default StallholderContext;

