import { ReactNode, createContext } from "react";
import { useState, useEffect } from "react";
import { ENFORCE_STOCK_IDS } from "@constants/defaults";
import { Cache } from 'aws-amplify/utils';

const ENFORCE = "enforce_stockIDs";

const EnforceStockIDsContext = createContext({
	enforce: false,
	setEnforce: (enforce: boolean) => {},
});

type EnforceStockIDsContextProps = {
  children?: ReactNode
};

export const EnforceStockIDsProvider = ({ children }: EnforceStockIDsContextProps) => {

  const [enforce, setEnforce] = useState<boolean>(ENFORCE_STOCK_IDS);

  useEffect(() => {

    Cache.getItem(ENFORCE)
    .then(result => {
      if(result) {
        setEnforce(result);
      }
    })
    return () => {
    };
  }, []);   

  useEffect(() => {

    Cache.setItem(ENFORCE, enforce);
  
    return () => {
    };
  }, [enforce]);   

  return (
    <EnforceStockIDsContext.Provider value={{enforce, setEnforce}}>
      {children}
    </EnforceStockIDsContext.Provider>
  );
};


export default EnforceStockIDsContext;

