import { useContext } from "react";
import TillAPIServiceContext from "@contexts/TillAPIServiceContext";

function useTillAPIService() {
  
  const context = useContext(TillAPIServiceContext);
  if (!context)
      throw new Error(
          'No APIServiceContext.Provider found when calling useAPIService.'
      );
  return context;
}

export default useTillAPIService;
