import log, { LogLevelNumbers } from "loglevel";
import {LOG_LEVEL} from "@constants/defaults";
import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";
import {DEBIT, CREDIT, CREDIT_FEE, DEBIT_FEE, QPITCH_FEE, CNP_FEE, CONTRA, CONTRA_CASH, CASH} from '@constants/payment_types'
import {GOOD, REFUNDED} from '@constants/status_types'
import currency from 'currency.js';

const options = { 
  minimumFractionDigits: 2,
  maximumFractionDigits: 2 
};

export function formatPrice(number: number) {
  return Number(number).toLocaleString('en', options);
}

export function formatPriceMaskingZero(number: number) {
  if (number === 0) return "-";
  return Number(number).toLocaleString('en', options);
}

export function parsePrice(number: string) {
  return Number(number.replace(/,/g, ''));
}

export async function focusAndOpenKeyboard(el: HTMLInputElement | null) {

  if(el) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    var __tempEl__ = document.createElement('input');
    __tempEl__.style.position = 'absolute';
    __tempEl__.style.top = (el.offsetTop + 7) + 'px';
    __tempEl__.style.left = el.offsetLeft + 'px';
    __tempEl__.style.height = '0';
    __tempEl__.style.opacity = '0';
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function() {
      el.focus();
      el.click();
      el.select();
      // Remove the temp element
      document.body.removeChild(__tempEl__);
    }, 100);
  }
}

const today = new Date();

export function isToday(date: Date) {
  return (today.toDateString() === date.toDateString());
}

export function turnLoggingOff() {
  try {
    console.log("Log level set to " + 5);
    log.setLevel(5);
  } catch (error) {
    console.log("LOG LEVEL COULD NOT BE READ");
  }
}

export function turnLoggingOn() {
  try {
    console.log("Log level set to " + 1);
    log.setLevel(1);
  } catch (error) {
    console.log("LOG LEVEL COULD NOT BE READ");
  }
}

export function setLogLevel() {


  console.log("log:", process.env.REACT_APP_LOG_LEVEL);
  try {
    var level: LogLevelNumbers = 1;
  
    switch (LOG_LEVEL) {
      case 0: level = LOG_LEVEL; break;
      case 1: level = LOG_LEVEL; break;
      case 2: level = LOG_LEVEL; break;
      case 3: level = LOG_LEVEL; break;
      case 4: level = LOG_LEVEL; break;
      case 5: level = LOG_LEVEL; break;
  
    }
  
    console.log("Log level set to " + level);
    log.setLevel(level);
  } catch (error) {
    console.log("LOG LEVEL COULD NOT BE READ");
  }
}

export function processTransactions(data: any) {

  log.debug("getTransactions fetched", data);
  
  if (data !== undefined) {
    data.forEach((transaction: any) => {
      transaction.timestamp = transaction.created + "T" + transaction.time
      if (transaction.isQPitch === undefined) transaction.isQPitch = false;
      if (transaction.isCNP === undefined) transaction.isCNP = false;
      transaction.net = calculateNet(transaction);
    });
    

    const sortedArray = data.sort((obj1: Object, obj2: Object) => {
      const key1 = "timestamp" as keyof typeof obj1;
      const key2 = "timestamp" as keyof typeof obj2;

      if (obj1[key1] < obj2[key2]) {
          return 1;
      }
  
      if (obj1[key1] > obj2[key2]) {
          return -1;
      }
  
      return 0;
    });        
    //setTransactions(sortedArray);
    return sortedArray;
    //log.info("sorted " + JSON.stringify(sortedArray));
  }
  log.warn("Error fetching transactions");
  return [];
}


export function calculateNet(transaction: CompletedTransaction) {

  if (transaction.status === GOOD) {
   
    if (transaction.paymentType === DEBIT)

      return transaction.price - ((transaction.price * DEBIT_FEE) + calculateFeeQPitch(transaction) + calculateCNPFee(transaction));

    if (transaction.paymentType === CREDIT) {
      const cardFee = transaction.price * CREDIT_FEE;
      const qPitchFee = calculateFeeQPitch(transaction);
      const cnpFee = calculateCNPFee(transaction);
      //log.debug("Fees:", transaction.price , cardFee, qPitchFee, cnpFee);
      return transaction.price - (cardFee + qPitchFee + cnpFee);
    }
    else
      return transaction.price - calculateFeeQPitch(transaction);
  }
  else if (transaction.status === REFUNDED) {
    if (transaction.paymentType === DEBIT) {
      return -((transaction.price * DEBIT_FEE) * 2) +  (calculateCNPFee(transaction) * 2);
    }
  }
  return 0;
}

function calculateFeeQPitch(transaction: CompletedTransaction) {

  if (transaction.isQPitch) return transaction.price * QPITCH_FEE;
  return 0;
}

function calculateCNPFee(transaction: CompletedTransaction) {

  if (transaction.isCNP) return CNP_FEE;
  return 0;
}

export type TransactionSummary = {
  credit: {gross: number, net: number, fees: number},
  debit: {gross: number, net: number, fees: number},
  card: {gross: number, net: number, fees: number},
  cash: {gross: number, net: number, fees: number},
  contra: number,
  contra_cash: number,
  refundedDebit: number,
  sales: {gross: number, net: number, fees: number},
  netSalesLessContra: number
};

export function createTransactionSummary(transactions: CompletedTransaction[]) {

  const summary : TransactionSummary = {
    credit: {gross: 0, net: 0, fees: 0},
    debit: {gross: 0, net: 0, fees: 0},
    card: {gross: 0, net: 0, fees: 0},
    cash: {gross: 0, net: 0, fees: 0},
    contra: 0,
    contra_cash: 0,
    refundedDebit: 0,
    sales: {gross: 0, net: 0, fees: 0},
    netSalesLessContra: 0
  };

  processTransactionsForSummary(transactions, summary);

  var debitFee = currency(summary.debit.gross).subtract(summary.debit.net);
  debitFee = debitFee.subtract(summary.refundedDebit);
  const debitNet = currency(summary.debit.gross).subtract(debitFee);
  summary.debit.net = debitNet.value;
  summary.debit.fees = debitFee.value;

  const creditFee = currency(summary.credit.gross).subtract(summary.credit.net);
  summary.credit.fees = creditFee.value
  
  const totalCard = currency(summary.debit.gross).add(summary.credit.gross);
  const totalCardNet = debitNet.add(summary.credit.net);
  const totalCardFee = debitFee.add(creditFee);
  summary.card.net = totalCardNet.value;
  summary.card.gross = totalCard.value;
  summary.card.fees = totalCardFee.value;

  const cashFees = currency(summary.cash.gross).subtract(summary.cash.net);
  summary.cash.fees = cashFees.value;

  summary.sales.gross = summary.cash.gross + summary.debit.gross + summary.credit.gross;
  summary.sales.net = totalCardNet.add(summary.cash.net).value;
  summary.sales.fees = cashFees.add(totalCardFee).value;

  var net = currency(summary.sales.net).subtract(summary.contra);
  net = net.subtract(summary.contra_cash);
  summary.netSalesLessContra = net.value;

  return summary;
}


function processTransactionsForSummary(transactions: CompletedTransaction[], summary: TransactionSummary) {
  log.info("processTransactions: ", transactions);
  transactions.forEach((transaction: CompletedTransaction) => {
    if (transaction.status === GOOD) {

      switch (transaction.paymentType) {
        case DEBIT: summary.debit.gross += transaction.price; summary.debit.net += transaction.net; break;
        case CREDIT: summary.credit.gross += transaction.price; summary.credit.net += transaction.net; break;
        case CASH: summary.cash.gross += transaction.price; summary.cash.net += transaction.net; break;
        case CONTRA: summary.contra += transaction.price; break;
        case CONTRA_CASH: summary.contra_cash += transaction.price; break;
        default: log.error("Sales processed unknown payment type:", transaction.paymentType);
      }
    }
    else if (transaction.status === REFUNDED && transaction.paymentType === DEBIT) {
      summary.refundedDebit += transaction.net;
    }
  });    
}

export function stripTimeFromDateString(date: string) {
  return date.split("T")[0];
}

export function stripTimeFromDate(date: Date) {
  return date.toISOString().split("T")[0];
}

const millisecondsPerDay = 24 * 60 * 60 * 1000;

export function dayBefore(date: Date) {

  var dayBefore = new Date();
  dayBefore.setTime(date.getTime() - millisecondsPerDay);
  return dayBefore;
}