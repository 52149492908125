import { useContext } from "react";
import StallholderHistoryContext from "@contexts/StallholderHistoryContext";

function useStallholderHistory() {
  
  const context = useContext(StallholderHistoryContext);
  if (!context)
      throw new Error(
          'No StallholderHistoryContext.Provider found when calling useStallholders.'
      );
  return context;
}

export default useStallholderHistory;
