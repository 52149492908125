import { useState, useEffect } from 'react'
import useStallholders from "@hooks/useStallholders";
import { Stallholder} from '@sharedtypes/Stallholder';
import { DeleteButton, NumberButton, ClearButton } from '@shared/Buttons';
import log from "loglevel";

type StallholderSearchProps = {
  stallholder?: Stallholder;
  setter: (stallholder: Stallholder | undefined) => void;
};

const StallholderSearch2 = ({ stallholder, setter }: StallholderSearchProps) => {

  const stallholdersContext = useStallholders();
  const [selected, setSelected] = useState<Stallholder | undefined>(stallholder);
  const [dealerID, setDealerID] = useState<string>("");
  const [format] = useState("bg-amber-500 hover:bg-amber-400 border-1 border-amber-900");

  useEffect(() => {

    if (stallholder) {
      setDealerID(stallholder.dealer_id);
    }
    return () => {
    };
  }, [stallholder]); 

  useEffect(() => {

    log.debug("Till, find dealer:", dealerID);
    const stallholder = stallholdersContext.findStallholderByID(dealerID);
    setSelected(stallholder);

    return () => {
    };
  }, [dealerID, stallholdersContext]); 

  useEffect(() => {

    setter(selected);

    return () => {
    };
  }, [selected, setter]); 


  function appendNumber(number: string) {
    setDealerID(dealerID + number);
  }

  function deleteNumber() {

    if (dealerID.length > 0) {
      setDealerID(dealerID.substring(0, dealerID.length -1));
    }
  }

  function clearNumber() {

    setDealerID("");
  }

  return (
    <div>
      <div className="h-10 grid grid-columns-2 justify-items-center">
        <p className="text-4xl font-extrabold">{dealerID} </p>
        {
          selected !== undefined ?
          <p> ( <span className="font-bold text-xl">{selected.name}</span> )</p> :
          <></>
        }        
      </div>
      <div className="grid grid-cols-3 gap-4 w-80 mt-12">
        <NumberButton value="1" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="2" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="3" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="4" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="5" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="6" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="7" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="8" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="9" onClick={appendNumber} classOverrides={format}/>
        <ClearButton  onClick={clearNumber} />
        <NumberButton value="0" onClick={appendNumber} classOverrides={format}/>
        <DeleteButton onClick={deleteNumber} />
      </div>

    </div>
  );
}

export default StallholderSearch2;
