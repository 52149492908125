import useTillAPIService from "@hooks/useTillAPIService";
import { useQuery } from 'react-query'
import log from "loglevel";

export type CashMark = {
  amount: number;
  till: string;
  timestamp: Date;
  isTransfer: boolean;
}

export type CashMarks = {
  day: string;
  reconciliationMarks:{};
  "0": CashMark[];
  "1": CashMark[];
  "2": CashMark[];
}

function remap(data: any) {
  log.info("Cash Marks fetched ", data);   
  const ground = data.marks["0"];
  const middle = data.marks["1"];
  const top = data.marks["2"];
  
  return {
    day: data.day,
    reconciliationMarks: data.reconciliationMarks,
    ground: ground,
    middle: middle,
    top: top
  };
}

const useCashMarks = () => {
  const apiService  = useTillAPIService();

  return useQuery( {
    queryKey: 'cashmarks', 
    queryFn: async () => apiService.fetch("getCashMarks", {}),
    select: (response) => remap(response),
    staleTime: Infinity,
    refetchInterval: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useCashMarks;
