import { ReactNode, createContext } from "react";
import { useState, useEffect } from "react";
import { Cache } from 'aws-amplify/utils';
import { Stallholder} from '@sharedtypes/Stallholder';
import log from "loglevel";

const HISTORY = "stallholder_history";
const LIMIT = 3;

const StallholderHistoryContext = createContext<StallholderHistoryContextValue | undefined>(undefined);

/*
const StallholderHistoryContext = createContext({
	history: [],
	addToHistory: (stallholder: Stallholder) => {},
});*/

type StallholderHistoryContextValue = {
  history: Stallholder[];
  addToHistory: (stallholder: Stallholder)  => void;
};

type StallholderHistoryContextProps = {
  children?: ReactNode
};

export const StallholderHistoryProvider = ({ children }: StallholderHistoryContextProps) => {

  const [history, setHistory] = useState<Stallholder[]>([]);

  useEffect(() => {

    Cache.getItem(HISTORY)
    .then(result => {
      if(result) {
        setHistory(result);
      }
    })
    return () => {
    };
  }, []);   

  useEffect(() => {

    Cache.setItem(HISTORY, history);
  
    return () => {
    };
  }, [history]);   

  function addToHistory(stallholder: Stallholder) {

    var newHistory = filterStallholders(stallholder, history);
    newHistory.unshift(stallholder);

    setHistory(newHistory);
  }

  return (
    <StallholderHistoryContext.Provider value={{history, addToHistory}}>
      {children}
    </StallholderHistoryContext.Provider>
  );
};

function filterStallholders(stallholder: Stallholder, history: Stallholder[]) {

  log.debug("stallholder filter", stallholder.id);

  var newHistory = history.filter((fav: Stallholder) =>
      fav.dealer_id.localeCompare(stallholder.dealer_id) !== 0
  );

  newHistory = newHistory.filter((fav: Stallholder, index: number) =>
      index < LIMIT
  );

  return newHistory;
}


export default StallholderHistoryContext;

