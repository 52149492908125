import { ReactNode, createContext, useEffect, useContext, useState, useMemo } from "react";
import { signOut, fetchAuthSession, getCurrentUser, JWT } from 'aws-amplify/auth';
import { fetchUserAttributes } from 'aws-amplify/auth';
import FloorContext from "@contexts/FloorContext";
import { Cache } from 'aws-amplify/utils';

import log from "loglevel";

export const handleSignOut = async () => {
  await signOut();
}

const TillAuthContext = createContext<TillAuthContextValue | undefined>(undefined);

type TillAuthContextProps = {
  children?: ReactNode
};

type TillAuthContextValue = {
  user: any | undefined;
  //attributes: any | undefined;
  getJwtToken: () => Promise<any>;
  jwt: any | undefined;
  signedIn: boolean;
};

export const TillAuthProvider = ({ children }: TillAuthContextProps) => {
  const {setFloor} = useContext(FloorContext);

  const [signedIn, setSignedIn] = useState(false);
  const jwt = useMemo(() => getJwtToken2(), []);
  const user = useMemo(() => getUser(), []);
  /*
  const attributes = useMemo(async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      log.debug("Floor:", userAttributes.nickname);

      if (userAttributes.nickname !== undefined)
        setFloor(parseInt(userAttributes.nickname));
      return userAttributes;
    } 
    catch (error) {
      log.warn(error);
    }
  }, [setFloor]);*/

  const getJwtToken = async () => {
    return fetchAuthSession();
  }
  

  useEffect(() => {

    log.info("USER:", user)
    setSignedIn(!!user);
    fetchUserAttributes()
    .then((userAttributes) => {
      log.debug("ATTS", userAttributes);
      if (userAttributes.nickname !== undefined)
        setFloor(parseInt(userAttributes.nickname));
        Cache.setItem("FLOOR", userAttributes.nickname);
    })
    .catch(function (error) {
      log.warn("Error fetching user attributes", error);
    });

    return () => {
    };
  }, [user, setFloor]);
  
  const value: TillAuthContextValue = {
    user,
    //attributes,
    getJwtToken,
    jwt,
    signedIn
  }

  return (
    <TillAuthContext.Provider value={value}>
      {children}
    </TillAuthContext.Provider>
  );
};

const getUser = async () => {

  const user  = await getCurrentUser()
  log.info("found user (app): " + JSON.stringify(user));

  if (user === undefined) return null;
  return user;
}

function getJwtToken2() : JWT | null {
  fetchAuthSession()
    .then((token) => {
      //log.debug("found tokens (app): " + JSON.stringify(token));

      if (token.tokens === undefined)
        return null;
      return token.tokens.accessToken;
    })
    .catch(function (error) {
      log.info("auth error:" + error);
      
    })
    return null;
}

export default TillAuthContext;
