import { useContext } from "react";
import StallholderContext from "@contexts/StallholderContext";

function useStallholder() {
  
  const context = useContext(StallholderContext);
  if (!context)
      throw new Error(
          'No StallholderContext.Provider found when calling useStallholder.'
      );
  return context;
}

export default useStallholder;
