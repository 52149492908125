import useTillAPIService from "@hooks/useTillAPIService";
import { useQuery } from 'react-query'
import log from "loglevel";

export type CashMark = {
  amount: number;
  till: string;
  timestamp: Date;
  isTransfer: boolean;
}

export type CashMarks = {
  day: string;
  reconciliationMarks:{};
  "0": CashMark[];
  "1": CashMark[];
  "2": CashMark[];
}

function remap(data: any) {
  log.info("Reconciliation dates fetched ", data);   
  const ground = data.marks["0"];
  const middle = data.marks["1"];
  const top = data.marks["2"];
  
  return {
    day: data.day,
    reconciliationMarks: data.reconciliationMarks,
    ground: ground,
    middle: middle,
    top: top
  };
}

const useLastReconciliation = (floor: number) => {
  const apiService  = useTillAPIService();

  return useQuery( {
    queryKey: 'reconciliation:' + floor, 
    queryFn: async () => apiService.fetch("getLastReconciliationDate", {floor: floor}),
    //select: (response) => remap(response),
    staleTime: 10000,
    //refetchInterval: 60000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useLastReconciliation;
