import Shell from "@shared/Shell";
import Table from "@shared/Table";
import {SpinnerWrapper} from "@shared/Spinner";
import useStallholders from "@hooks/useStallholders";

const columns = [
  {
    "id": "dealer_id",
    "label": "ID",
    "cellStyle": (row: number) => "px-6 py-1 font-medium text-lg text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800",
    "headerStyle": "px-6 py-3 bg-gray-50 dark:bg-gray-800"
  },
  {
    "id": "name",
    "label": "Name",
    "cellStyle": (row: number) => "px-6 py-1 text-lg",
    "headerStyle": "px-6 py-4"
  }
]

export function Dealers() {

  const stallholderContext = useStallholders();

  const footer = <tfoot>
                    <tr className="font-semibold text-gray-900 dark:text-white">
                      <th scope="row" className="px-6 py-3 text-base">{stallholderContext.stallholders.length} Dealers</th>
                    </tr>
                  </tfoot>

  return (
    <>
      <Shell pageIndex={2}>
        <div>
          {
            stallholderContext.stallholders.length === 0 ?
            <SpinnerWrapper /> :
            <Table data={stallholderContext.stallholders} columns={columns} footer={footer}/>
          }
          
        </div>
      </Shell>    
    </>
  );
}

export default Dealers;
