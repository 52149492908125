import Shell from "@shared/Shell";
import Table from "@shared/Table";
import { useState, useEffect, Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CashPad from "@components/CashPad";
import {CloseButton} from "@shared/Buttons";
import useCashMarks, {CashMark} from "@hooks/useCashMarks";
import {ZERO} from "@hooks/useTill";
import log from "loglevel";
import useTillAPIService from "@hooks/useTillAPIService";
import { formatPrice, parsePrice } from "@shared/Utils";
import dateFormat from "dateformat";
import useStallholder from "@hooks/useStallholder";
import FloorContext from "@contexts/FloorContext";
import { FLOORS } from "@constants/floors";

import  TillDetails  from '@components/TillDetails2';
import useTransactionsForDay from "@hooks/useTransactionsForDay";
import {GOOD} from '@constants/status_types'
import {CASH, CONTRA_CASH} from '@constants/payment_types'
import {SpinnerWrapper} from "@shared/Spinner";
import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";

//   const mark = {isTransfer: isTransfer, amount: amount, till: till, timestamp: today};

const columns = [
  {
    "id": "amount",
    "label": "Amount",
    "cellStyle": (row: number) => "px-6 py-1 font-medium text-lg text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800",
    "headerStyle": "px-6 py-3 bg-gray-50 dark:bg-gray-800",
    "format": (cell: any, row: {}, index: number) => { 
      return formatPrice(cell);
    }
  },
  {
    "id": "timestamp",
    "label": "Time",
    "cellStyle": (row: number) => "px-6 py-1 text-lg",
    "headerStyle": "px-6 py-4",
    "format": (cell: any, row: {}, index: number) => { 
      const date = new Date(cell);
      return dateFormat(date, "h:MM");
    }
  },
  {
    "id": "till",
    "label": "Dealer",
    "cellStyle": (row: number) => "px-6 py-1 text-lg",
    "headerStyle": "px-6 py-4"
  }
]

const THIRTY = "30.00";

// investigate why so many remaps for cash
export function Cash() {
  const [marks, setMarks] = useState<CashMark[]>([]) ;
  const [today] = useState(dateFormat(new Date(), "dddd, mmmm dS")) ;

  const {data, refetch, isLoading} = useCashMarks();
  const {floor} = useContext(FloorContext);

  useEffect(() => {
    log.info("marks:", data);
    log.info("floor:", floor);


    if (data !== undefined && floor > -1) {
      if (floor === 0)
        setMarks(data?.ground);
      else if (floor === 1)
        setMarks(data?.middle);
      else if (floor === 2)
        setMarks(data?.top);
    }
  return () => {
  };
}, [data, floor]); 

  return (
    <>
      <Shell pageIndex={3}>
        <div>
          <div className="relative flex items-center justify-between">    
            <div>
              <TillDetails />
            </div>
          </div>
          <h1 className="mt-10 mb-2 text-xl font-bold text-left">Till cash on {FLOORS[floor]} floor on {today}</h1>
          {
            isLoading ?
            <SpinnerWrapper /> :
            <div>
              <Summary marks={marks} floor={floor}/>
              <MarkSummary isTransfer={false} description="Cash in till" marks={marks} refetch={refetch} defaultAmount={THIRTY}/>
              <MarkSummary isTransfer={true} description="Cash moved to the office" marks={marks} refetch={refetch} defaultAmount={ZERO}/>  
            </div>
          } 
        </div>
      </Shell>    
    </>
  );
}

type SummaryProps = {

  marks: CashMark[];
  floor: number;
};

const Summary = ({ marks, floor}: SummaryProps) => {

  const {isLoading, data, refetch} = useTransactionsForDay(new Date(), floor);
  const [cash, setCash] = useState("");

  useEffect(() => {

    if (!isLoading){
        
      var total = 0;
      
      //= marks[marks.length-1].amount;

      data.forEach((transaction: CompletedTransaction) => {
        
        if (transaction.paymentType === CASH && transaction.status === GOOD) {
          total = total + transaction.price;
        }
        else if (transaction.paymentType === CONTRA_CASH && transaction.status === GOOD) {
          total = total - transaction.price;
        }

      });

      var cashStart = 0;
      marks.forEach((mark: CashMark) => {
        
        if (mark.isTransfer) {
          total = total - mark.amount;
        }
        else {
          cashStart = mark.amount;
        }

      });

      total = total + cashStart;

      setCash(formatPrice(total));
    }

  return () => {
  };
}, [marks, floor, data, isLoading]); 

  return(
    <>
      <h1 className="mt-10 mb-2 text-2xl font-bold text-left">Expected cash in till: £{cash}</h1>

    </>
  )
}

type MarkSummaryProps = {
  isTransfer: boolean;  
  description: string;
  marks: CashMark[];
  refetch: Function;
  defaultAmount: string;
};

const MarkSummary = ({ isTransfer, description, marks, refetch, defaultAmount}: MarkSummaryProps) => {

  const [showDialog, setShowDialog] = useState(false) ;
  const [filteredMarks, setFilteredMarks] = useState<CashMark[]>([]) ;

  useEffect(() => {
    
    const filtered = marks.filter((mark: CashMark) =>
                        mark.isTransfer === isTransfer
                      );
    setFilteredMarks(filtered);
    
  return () => {
  };
}, [marks, isTransfer]); 
 
  function openDlg() {
    setShowDialog(true);
  }

  return (
    <div>
      <MarkDialog isOpen={showDialog} close={()=>setShowDialog(false)} isTransfer={isTransfer} refetch={refetch} defaultAmount={defaultAmount}/>
      <h1 className="mt-10 mb-2 text-2xl font-bold text-left">{description}</h1>
      <div>
      {
        <Table data={filteredMarks} columns={columns} /> 
      }
      </div>
      <div className="flex justify-start ">
        <button 
            onClick={openDlg}
            className="mt-10 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
            {isTransfer ? "Transfer" : "Mark"}
          </button>
      </div>
    </div>
  );
}

type MarkDialogProps = {
  isOpen: boolean;
  close: () => void;
  isTransfer: boolean;  
  refetch: Function;
  defaultAmount: string;
};

const transferDescr = "Record cash transferred from this till to the office safe."
const markDescr = "Record cash in this till at this moment."

const transferButton = "Record transfer"
const markButton = "Record cash"

const markAPICall = "markCash";
const transferAPICall = "markTransfer";


const MarkDialog = ({ isOpen, close, isTransfer, refetch, defaultAmount}: MarkDialogProps) => {
 
  const [amount, setAmount] = useState(defaultAmount) ;
  const [processing, setProcessing] = useState(false);
  const apiTillService  = useTillAPIService();
  const stallholderContext = useStallholder();
  const {floor} = useContext(FloorContext);

  function processMark() {

    if (amount.valueOf() !== ZERO.valueOf()) {

      setProcessing(true);

      if (stallholderContext.stallholder !== undefined) {

        apiTillService.fetch(isTransfer ? transferAPICall : markAPICall, 
          {
            amount: parsePrice(amount), 
            till: stallholderContext.stallholder.dealer_id,
            floor: floor
          }
          )
          .then((data: any) => {
            log.info("added mark " + JSON.stringify(data));
            refetch();
            setProcessing(false);
            close();
            setAmount(defaultAmount);
            })
          .catch(function (error: any) {
            log.info("Error editing transaction:" + error);
          }
        )
      }
    }
  }

  function doClose() {
    setAmount(defaultAmount);
    close();
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={doClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="flex justify-end mb-5">
                  <CloseButton onClick={doClose}/>
                </div>
                <div className="mb-10 text-lg">
                  {isTransfer ? transferDescr : markDescr}
                </div>
                <div>
                  <CashPad setter={setAmount} initialPrice={amount}/>
                </div>
                <div className="flex justify-center">
                  <button 
                    onClick={processMark}
                    disabled={Number(amount) === 0|| processing || stallholderContext.stallholder === undefined}
                    className="mt-10 px-6 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {isTransfer ? transferButton : markButton}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Cash;
