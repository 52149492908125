import React, {ReactNode} from 'react'
import { useState, useEffect, useContext } from 'react'
import {  QuestionMarkCircleIcon, 
          InformationCircleIcon, 
          UserGroupIcon, 
          CurrencyPoundIcon,
        } from '@heroicons/react/20/solid'
import StallholderSearch2 from "@components/StallholderSearch2";
import CashPad from "@components/CashPad";
import { focusAndOpenKeyboard } from "@shared/Utils";
import { Stallholder} from '@sharedtypes/Stallholder';
import {  Transition} from '@headlessui/react'
import useStallholderHistory from "@hooks/useStallholderHistory";
import EnforceStockIDsContext from "@contexts/EnforceStockIDsContext";
import log from "loglevel";
import { Switch } from '@headlessui/react'
import classnames from 'classnames';
import FloorContext from "@contexts/FloorContext";

const VISIBLE = "visible z-10";
const INVISIBLE = "invisible z-0 h-0";

type TransactionEditProps = {
  description: string;
  price: string;
  stock: string;
  dealer: Stallholder | undefined; 
  isQPitch: boolean,
  setPrice: (price: string) => void;
  setDealer : (stallholder: Stallholder | undefined) => void;
  setDescription: (description: string) => void;
  setStock: (stock: string) => void;
  setIsQPitch: (isQPitch: boolean) => void;
  children?: ReactNode;
  showDealer: boolean;
  setShowDealer: (showDealer: boolean) => void;
  showFavs: boolean;
};

const TransactionEdit = ({  description, 
                            price, 
                            stock, 
                            dealer, 
                            isQPitch,
                            setPrice, 
                            setDealer, 
                            setStock, 
                            setDescription, 
                            children, 
                            showDealer, 
                            setShowDealer,
                            setIsQPitch,
                            showFavs}: TransactionEditProps) => {

  const {enforce} = useContext(EnforceStockIDsContext);
  const {floor} = useContext(FloorContext);

  const [showPrice, setShowPrice] = useState(false);

  const [visiblePrice, setVisiblePrice] = useState(VISIBLE);
  const [visibleStock, setVisibleStock] = useState(INVISIBLE);
  const [visibleDescription, setVisibleDescription] = useState(INVISIBLE);

  const stockInput = React.useRef<HTMLInputElement>(null);
  const descriptionInput = React.useRef<HTMLInputElement>(null);

  const {addToHistory} = useStallholderHistory();

  useEffect(() => {

    if (showDealer) {
      setShowPrice(false);
      setShowDealer(true);
      setTimeout(function() {
        
        switchToPriceVisible();
      }, 100);

    }
    return () => {
    };
  }, [showDealer, setShowDealer]); 

  function switchToPriceVisible() {
    setVisiblePrice(VISIBLE);
    setVisibleStock(INVISIBLE);
    setVisibleDescription(INVISIBLE);
  }

  function switchToStockVisible() {
    setVisiblePrice(INVISIBLE);
    setVisibleStock(VISIBLE);
    focusAndOpenKeyboard(stockInput.current);
  }

  function switchToDescriptionVisible() {
    setVisibleStock(INVISIBLE);
    setVisibleDescription(VISIBLE);
    focusAndOpenKeyboard(descriptionInput.current);
  }

  const handleStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    setStock(value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    setDescription(value);
  };

  function setDealerFromFav(stallholder: Stallholder | undefined) {

    log.debug("setDealerFromFav", stallholder);
    setDealer(stallholder);
    setShowDealer(false);
  }

  function dealerSelected() {
    setShowDealer(false);
    /// placed here because want to do when sure that is right dealer number else it captures 
    /// partial dealer numbers eg i put in 345 but it treats 34 as part of the history if 34 is a valid dealer number    if (dealer !== undefined)
    if (dealer !== undefined)
      addToHistory(dealer);
  }

  const handleQPitchChange = (state: boolean) => {
    log.debug("handleQPitchChange", state);
    setIsQPitch(state);
  };

  const labelFormat = "text-2xl leading-6 text-gray-900 mb-6";

  return (
    <div>
      {
        (showDealer && showFavs) ? <Favs setDealer={setDealerFromFav}/> : <></>
      }
      <div className="h-96 grid grid-cols-1 justify-items-center ">
        <Transition
          show={showDealer}
          enter="transition-opacity ease-linear duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {setShowPrice(true)}}
        >
          <div className="flex justify-center">
            <UserGroupIcon className="mr-2 h-6 w-6 text-indigo-600"/>
            <p className={labelFormat}>Enter the dealer ID</p>
          </div>
          <StallholderSearch2 stallholder={dealer} setter={setDealer} />
          <div className="flex justify-center">
            <button 
              onClick={dealerSelected}
              disabled={dealer === undefined}
              className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </Transition>


        <Transition
          show={showPrice}
          enter="transition-opacity ease-linear duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={visiblePrice}>
            <div className="flex justify-center">
              <CurrencyPoundIcon className="mr-2 h-6 w-6 text-indigo-600"/>
              <p className={labelFormat}>Enter the price</p>
            </div>
            <CashPad setter={setPrice} initialPrice={price}/>
            <div className="flex justify-center">
              <button 
                onClick={switchToStockVisible}
                disabled={Number(price) === 0}
                className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Next
              </button>
            </div>
          </div>
        
          <div className={visibleStock}>
            <label htmlFor="stock-id" className={labelFormat}>
              Enter the stock ID
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            
              <input
                value={stock}
                type="text"
                ref={stockInput} 
                name="stock"
                id="stock"
                className= "block border-0 w-full rounded-md py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-2xl"             
                onChange={handleStockChange}
                placeholder="0000"
              />
              
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
            <div>
              {
                floor === 0 ?
                <Switch.Group as="div" className="mt-4 flex items-center justify-end">
                <span className="mr-4">
                  <Switch.Label as="span" className="font-medium leading-6 text-gray-400" passive>
                  Sold from Q Pitch
                  </Switch.Label>
                </span>
                <Switch
                  checked={isQPitch}
                  onChange={handleQPitchChange}
                  className={classnames(
                    isQPitch ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classnames(
                      isQPitch ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group> :
              <></>
              }

            </div>
            <div className="flex justify-center">
              <button 
                disabled={enforce && stock.length === 0}
                onClick={switchToDescriptionVisible}
                className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Next
              </button>
            </div>
          </div>          

          <div className={visibleDescription}>
            <div >
              <label htmlFor="description" className={labelFormat}>
                Add a description
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  value={description}
                  type="text"
                  ref={descriptionInput} 
                  name="description"
                  id="description"
                  onChange={handleDescriptionChange}
                  //onFocus={callClick}
                  className="block border-0 w-full rounded-md py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-2xl"    
                  placeholder="e.g. An oak cabinet"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>   
            <div>
              <div className="grid grid-cols-1 justify-items-center">
                {children}
              </div>  
            </div> 
          </div>
        </Transition>
      </div>
    </div>
  );
}

type FavsProps = {
  setDealer: (stallholder:Stallholder | undefined) => void
};

const Favs = ({ setDealer }: FavsProps) => {

  const {history} = useStallholderHistory();

  return (
    <>
      {
        history.length > 0 
        ?
        <div className="absolute z-10 top-36 right-20 rounded-lg">
        <div className="rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
            Favourites
          </label>
          <div className="grid grid-cols-2 gap-2 p-3">
            {
              history.map((stallholder: Stallholder) => (
                <div key={stallholder.dealer_id}>
                  <button 
                    className="rounded-full border-1 border-pink-900 w-12 h-12 bg-pink-500 p-1.5 text-white shadow-sm hover:bg-pink-700  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={()=>setDealer(stallholder)}
                    >
                    {stallholder.dealer_id}
                  </button>
                </div>
              ))
            } 
          </div>
        </div>
      </div>
      :
      <></>
      }

    </>
  )
}

export default TransactionEdit;
